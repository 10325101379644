import clsx from 'clsx';
import Image from 'next/image';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { LineArt, TextButton } from 'src/components';
import { ArrowLeft } from 'src/icons';
import { MarkdownFragment, TFunction, useTranslations } from 'src/renderless/useTranslations';

const cmsLocation = 'error.404';
const useContent = (t: TFunction) =>
  ({
    title: t(`${cmsLocation}.title`),
    body: t(`${cmsLocation}.body`),
    back: t(`${cmsLocation}.back`),
  } as { [key: string]: string });

const The404Page = () => {
  const { back } = useRouter();
  const { t } = useTranslations();
  const content = useContent(t);

  return (
    <>
      <Head>
        <title>Homeppl Portal</title>
      </Head>

      <div className="w-screen overflow-x-hidden overflow-y-auto relative h-full pb-24">
        <div className="bg-blue rounded-b-[50%] -translate-x-[50vw] lg:-translate-x-[5vw] w-[200vw] lg:w-[110vw] text-center pt-16 pb-16">
          <div className="absolute top-0 left-0 rounded-b-[50%] h-full w-full overflow-hidden">
            <span
              className={clsx(
                'absolute  bottom-[1.4rem] lg:-top-12 lg:right-0',
                'right-[48vw] sm:right-[30vw]',
                'top-12',
              )}
            >
              <LineArt name="topRight" />
            </span>
            <span
              className={clsx(
                'absolute  bottom-0 lg:-top-12 lg:right-0',
                'right-[36vw] sm:right-[25vw]',
                'top-16  sm:top-8',
              )}
            >
              <LineArt name="topRight2" />
            </span>
            <span className="hidden lg:block absolute bottom-0 -left-6">
              <LineArt name="bottomLeft" />
            </span>
            <span className="hidden lg:block absolute bottom-0 -left-6">
              <LineArt name="bottomLeft2" />
            </span>
          </div>
          <Image
            className="pt-2 scale-75 sm:scale-100"
            src="/assets/404.svg"
            alt="404"
            width={479}
            height={178}
          />
          <div className="absolute inset-x-0 -left-32 -bottom-12">
            <Image src="/assets/furniture.svg" width="313" height="207" alt="furniture" />
          </div>
        </div>
        <div className="text-center mt-16 px-4 m-auto">
          <h1 className="text-grey-900 text-4xl mb-4">{content.title}</h1>
          <MarkdownFragment className="text-lg text-grey-800">{content.body}</MarkdownFragment>
          <TextButton onClick={back} className="m-auto mt-8" iconLeft={ArrowLeft}>
            {content.back}
          </TextButton>
        </div>
      </div>
    </>
  );
};

export default The404Page;
